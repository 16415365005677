.ma{
    justify-content:center ;
}

.padbot{
    padding-bottom: 9rem;
}
.banner-text{
    margin-bottom: 3rem;
}

body {
  
  background-size: cover; /* Adjusts the background size to cover the entire screen */
  background-repeat: no-repeat; /* Ensures that the background image doesn't repeat */
  background-attachment: fixed; /* Fixes the background image position */
  /* You can add additional styles here, such as font-family, text-color, etc. */
}

.dropdown {
    position: relative;
    display: inline-block;
    margin-right: 40px;
  }

  /* Style the dropdown button */
  .dropdown a {
    display: inline-block;
    text-decoration: none;
    color: black;
    padding: 10px;
  }

  /* Style the dropdown content (hidden by default) */
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }

  /* Style the dropdown items */
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    display: block;
    text-decoration: none;
  }

  /* Change color on hover */
  .dropdown-content a:hover {
    background-color: #f1f1f1;
  }

  /* Show the dropdown content when the link is hovered over */
  .dropdown:hover .dropdown-content {
    display: block;
  }


  .fnHZMJ{
    background: none!important;
  }

  .iZWtgM{
    background-color: unset!important;

  }